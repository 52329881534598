import * as React from 'react';
import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SelectList from './SelectRef';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Importer from './Importer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton'
import Icon from '@mui/material/Icon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import UploadFile from './UploadFile';
import UnitSelection from './SelectTerrUnit';
import MatchingTable from './MatchingTable';
import StatusTable from './StatusTable';
import { a11yProps, CustomTabPanel } from '../ui/Tabs';
import { StoreFile, StoreRef } from '../../hooks/store';
import { FileArray } from '../../utils/types';
import { errorUtils } from '../../utils/api/configs/axiosConfigs';

/**
 * Main component that handles the file import and analysis process.
 * 
 * This component includes:
 * - Tabs for selecting between simple consolidated list and consolidated list with statuses.
 * - Stepper to guide the user through the import and analysis steps.
 * - Help section with instructions for the user.
 * - File upload and reset functionality.
 * - Error handling and display.
 * - Conditional rendering based on the current step and tab selection.
 * 
 * @component
 * @returns {JSX.Element} The rendered component.
 */

export default function Main() {

	const steps = ['Import du fichier', 'Analyse des correspondances'];
	const stepsStatus = ['Import du fichier', 'Analyse des correspondances', 'Prévisualisation des statuts'];

	const [tab, SetTab] = React.useState(0);
	const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
		SetTab(newValue);
	};

	const [file, setFile] = React.useState<File>();
	const [referentiel, setReferentiel] = React.useState('');
	const [refVersion, setRefVersion] = React.useState('');
	const [terrUnit, setTerrUnit] = React.useState('');
	const [fileData, setfileData] = React.useState<FileArray[]>([]);
	const [taxonList, setTaxonList] = React.useState<string[]>([]);
	const [errorMessage, setErrorMessage] = React.useState("");

	const [help, setHelp] = React.useState(true);
	const handleChangeHelp = (event: React.ChangeEvent<HTMLInputElement>) => {
		setHelp(event.target.checked);
	};

	const [activeStep, setActiveStep] = React.useState(0);
	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	const handleReset = () => {
		setActiveStep(0);
		window.location.reload();
	};

	const { isFinished: isFinishedFile } = StoreFile();
	const { isLoading: isLoadingFile } = StoreFile();
	const { isFinished: isFinishedRef } = StoreRef();

	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
	  setOpen(true);
	};
	const handleClose = () => {
	  setOpen(false);
	};

	function cancelUpload() {
		handleClose();
		handleReset();
	};

	useEffect(() => {
		StoreFile.setState({isLoading: false});
	}, [isFinishedFile]);

	useEffect(() => {
		if (errorUtils.lastErrorMessage !== "") {
		  setErrorMessage(errorUtils.lastErrorMessage);
		}
	  }, [errorUtils.lastErrorMessage]);

	return (
		<Box sx={{ width: '100%' }}>

			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
					<Tab 
						label="Liste consolidée simple"
						disabled={activeStep >= 1}
						{...a11yProps(0)}
					/>
					<Tab 
						label="Liste Consolidée avec statuts"
						disabled={activeStep >= 1}
						{...a11yProps(1)}
					/>
				</Tabs>
			</Box>

			<CustomTabPanel value={tab} index={0}>
				<Stepper sx={{ mt: 4, mb: 1 }} activeStep={activeStep} alternativeLabel>
				{steps.map((label) => {
					return (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
					);
				})}
				</Stepper>
			</CustomTabPanel>

			<CustomTabPanel value={tab} index={1}>
				<Stepper sx={{ mt: 4, mb: 1 }} activeStep={activeStep} alternativeLabel>
					{stepsStatus.map((label) => {
						return (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
						);
					})}
				</Stepper>
			</CustomTabPanel>
			<FormControlLabel control={<Switch defaultChecked onChange={handleChangeHelp}/>} label="Aide"/>
			{help && (
				<Typography variant='body2'>
					<p>
					- Le document à importer doit être au format ".csv" et contenir la colonne "scientificName". Les noms scientifiques renseignés ne doivent pas contenir des points virgules.
					<br/>- Choisissez l'option de liste sans statuts ou avec statuts. L'application ne fera pas plusieurs imports de fichiers à la fois.
					<br/>- Une fois le fichier de données et le référentiel renseigné, importez pour passer à la suite.
					<br/>- Après l'import, vous pouvez modifier sur le tableau des correspondances les taxons non reconnus ou mal associés en cliquant 2 fois dans la colonne "Nom consolidé".
					<br/>- Quand tous les taxons sont renseignés, vous aurez l'option de générer et prévisualiser la liste des statuts si vous l'avez choisi.
					</p>
				</Typography>
			)}

			{file && (
			<React.Fragment>
				<Box sx={{
					display:"flex",
					justifyContent:" center",
					minWidth: 300
				}}>
					<Button
						color='primary'
						onClick={handleClickOpen}
						variant='contained'
						sx={{ m: 1 }}
					>
						Réinitialiser
					</Button>
					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">
							⚠️ Attention ⚠️
						</DialogTitle>
						<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Vous êtes sur le point de réinitialiser la consolidation du fichier actuel,<br/>
							êtes-vous sûr de vouloir continuer ?
						</DialogContentText>
						</DialogContent>
						<DialogActions>
						<Button onClick={handleClose}>Non</Button>
						<Button onClick={cancelUpload} autoFocus>
							Oui
						</Button>
						</DialogActions>
					</Dialog>
				</Box>
			</React.Fragment>
			)}

			<Typography variant='h6' sx={{ mb: 2, mt: 2}}>
				{activeStep >= 1 && (
					<Icon color="primary" sx={{ mr: 0.5}}>
						<CheckCircleIcon sx={{ m: 0.1 }}/>
					</Icon>
				)}
				Import du fichier
			</Typography>

			{activeStep == 0 && (
				<React.Fragment>

					<CustomTabPanel value={tab} index={0}>
						<Importer setFile={setFile}/>
					</CustomTabPanel>

					<CustomTabPanel value={tab} index={1}>
						<Grid container spacing={2} columns={16}>
							<Grid item xs={8}>
								<Importer setFile={setFile}/>
							</Grid>
							<Grid item xs={8} alignItems={'center'}>
								<UnitSelection setTerrUnit={setTerrUnit} />
							</Grid>
						</Grid>
					</CustomTabPanel>

					<SelectList setReferentiel={setReferentiel} setRefVersion={setRefVersion} />

					<Box sx={{
						display:"flex",
						justifyContent:" center",
						minWidth: 300
					}}>
						{errorMessage !== "" && (
							<Box sx={{
								display:"flex",
								justifyContent:" center",
								minWidth: 300
							}}>
								<Typography 
									sx={{ mt: 2, mb: 1, color: "red" }}
								>
									{errorMessage}
								</Typography>
							</Box>
						)}
						{isFinishedFile === true && isFinishedRef === true && file && !isLoadingFile && (
							<UploadFile file={file} referentiel={referentiel} terrUnit={terrUnit} tab={tab} setActiveStep={setActiveStep} setFileData={setfileData}/>
						)}
						{isLoadingFile === true && (
							<LoadingButton
								loading={true}
								startIcon={<SaveIcon />}
								loadingPosition="start"
								variant="contained"
								color="secondary"
								sx={{ mr: 1 }}
							>
								Traitement du fichier...
							</LoadingButton>
						)}
					</Box>
				</React.Fragment>
			)}

			{activeStep >= 1 && (
				<React.Fragment>
					<Typography variant='h6' sx={{ mb: 2, mt: 2}}>
						{activeStep >= 2 && (
							<Icon color="primary" sx={{ mr: 0.5}}>
								<CheckCircleIcon sx={{ m: 0.1 }}/>
							</Icon>
						)}
						Analyse des correspondances
					</Typography>
					{errorMessage !== "" && (
						<Box sx={{
							display:"flex",
							justifyContent:" center",
							minWidth: 300
						}}>
							<Typography 
								sx={{ mt: 2, mb: 1, color: "red" }}
							>
								{errorMessage}
							</Typography>
						</Box>
					)}
					{activeStep == 1 && errorMessage == "" && (
						<React.Fragment>
							<MatchingTable fileData={fileData} setTaxonList={setTaxonList} handleNext={handleNext} tab={tab} setfileData={setfileData} refVersion={refVersion} errorMessage={errorMessage} />
						</React.Fragment>
					)}
				</React.Fragment>
			)}

			{activeStep >= 2 && tab == 1 && (
				<React.Fragment>
					<Typography variant='h6' sx={{ mt: 2, mb: 1 }}>
						Prévisualisation des statuts
					</Typography>
					{errorMessage !== "" && (
						<Box sx={{
							display:"flex",
							justifyContent:" center",
							minWidth: 300
						}}>
							<Typography 
								sx={{ mt: 2, mb: 1, color: "red" }}
							>
								{errorMessage}
							</Typography>
						</Box>
					)}
					{activeStep == 2 && errorMessage == "" && (
						<React.Fragment>
							<StatusTable taxonList={taxonList} />
						</React.Fragment>
					)}
				</React.Fragment>
			)}
		</Box>
	);
}