import React, { useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { StoreTaxaName, StoreValidTaxa } from '../../hooks/store';
import { TaxaTruncate } from '../../utils/types';

export function TaxaNameSelection({rowId, rowsArray, setRows}: any) {

	const [filteredResults, setFilteredResults] = React.useState<TaxaTruncate[]>([]);
  const [consolidationResult, setConsolidationResult] = React.useState<any>('');
  const {search, response} = StoreTaxaName();
  const [searchInput, setSearchInput] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const loading = open && searchInput.length > 2  && filteredResults != ([]);
  const { find, validNameResponse } = StoreValidTaxa();

  //useEffect utilisé pour mettre à jour le champs qui permet la recherche de taxon et la consolidation
	useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {
      if (active) {
        setFilteredResults([]);
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);

  //useEffect utilisé pour aller chercher le nom valide du taxon qui a été choisie pour la consolidation
  useEffect(() => {
    if(consolidationResult){
      find(consolidationResult.ref).then(() => {
      if (validNameResponse) {
        setRows((rows: any[]) => 
          rows.map((row, index) => 
            index === rowId - 1 ? 
            { ...row, validName: validNameResponse.name } : 
            row
          ));
        }
      });
    }
  }, [consolidationResult, validNameResponse]);

  function searchItems (_event: React.SyntheticEvent, value: string)  {
    setSearchInput(value)
    if (value !== '' && value.length > 2) {
      search(value).then(() => {
        if (response) {
          setFilteredResults(response);
        }
      });
    }
  }

  function handleChangeRow(newValue: any) {
    //ici on met à jour la colone éditable qui va chercher le taxon consolidé avec la nouvelle valeur choisie
    setRows((rows: any[]) => 
      rows.map((row, index) => 
        index === rowId - 1 ? 
        { ...row, rightValueName: newValue.name, rightValueKey: newValue.key } : 
        row
      )
    );
    //ici on met à jour le nom valide directement uniquement si le nouveau taxon sélectionné est son propre nom valide
    if(newValue.key==newValue.ref){
      setRows((rows: any[]) => 
        rows.map((row, index) => 
          index === rowId - 1 ? 
          { ...row, validName: newValue.name } : 
          row
        )
      );
    }else{
      //ici on va chercher le nom valide du nouveau taxon sélectionné
      setConsolidationResult(newValue);
    }
    // ici on met à jour les colonnes cachés qui indiquent si le taxon a été correctement trouvé pour les filtres
    const isTaxonFound = newValue.name === rowsArray[rowId - 1].leftValueName;
    const isTaxonAlmostFound = newValue.name.toLowerCase().includes(searchInput.toLowerCase()) && !isTaxonFound;
    setRows((rows: any[]) => 
      rows.map((row, index) => 
      index === rowId - 1 ? 
      { ...row, isTaxonFound, isTaxonAlmostFound } : 
      row
      )
    );
  }

	return (
		<Autocomplete
      fullWidth={true}
			options={response?.taxons ?? []}
			getOptionLabel={(option:any) => option.name}
			isOptionEqualToValue={(option, name) => option.name === name.name}
			onInputChange={searchItems}
      onChange={(_event, newValue) => handleChangeRow(newValue)}
			loading={loading}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.key}>
						{option.name}
					</li>
				);
			}}
			renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
					fullWidth={true}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
		/>
	)
}