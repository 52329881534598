import Button from '@mui/material/Button';
import PublishIcon from '@mui/icons-material/Publish';
import { StoreFile } from '../../hooks/store';
import { TerrUnitArray } from '../../utils/types';
import { useEffect } from 'react';
import { errorUtils } from '../../utils/api/configs/axiosConfigs';

export default function UploadFile({ file, referentiel, terrUnit, tab, setActiveStep, setFileData }: any) {
	const { upload, response } = StoreFile();

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      const importType = tab === 0 ? "biblio" : "status-biblio";
      var terrUnitKey = "";
      formData.append("fileToUpload", file);
      formData.append("refId", referentiel);
      if (terrUnit.length > 0) {
        terrUnitKey = terrUnit.map((unit: TerrUnitArray) => unit.key).join(",");
        formData.append("terrUnits", terrUnitKey);
      } else {
        formData.append("terrUnits", "");
      }
      try {
        upload(formData, importType).then(async () => {
          if (errorUtils.lastErrorMessage === "") {
            setActiveStep(1);
          } else {
            console.log(errorUtils.lastErrorMessage);
          }
        });
      } catch (error) {
        console.log("test");
      }
    }
  };

  useEffect(() => {
    setFileData(response);
  }, [response]);

	return (
    <Button
      startIcon={<PublishIcon />}
      color='primary'
      onClick={handleUpload}
      variant='outlined'
      sx={{ mr: 1 }}
    >
      Importer
    </Button>
	);
}