import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { RefService } from '../utils/api/RefService';
import { LinnaeService } from '../utils/api/LinnaeService';
import { FileStore, RefStore, FileData, TerrUnitStore, TaxaSearchStore, TaxaValidStore, ValidTaxa, taxons, TaxonStatusStore, StatusTaxonResponse /*TaxaVersionStore, TaxaVersion*/ } from '../utils/types';

export const StoreRef = create(persist<RefStore>(
    (set) => ({
        isLoading: false,
        isFinished: false,
        search: async () => {
            try {
                set({ isLoading: false, isFinished: false});
                const data:Array<{ key: string; name: string;ordre:number;terrUnitKey: string;ref:string}> = await RefService.getAllRefType();
                set(() =>({ isLoading: false, response:data, isFinished: false }));
            }   catch (err: any) {
                const error =
                err?.message || err?.data?.message || 'Unexpected network error.';
                set(() => ({ isLoading: false, error,isFinished: false }));
            }
        },
    }),
    {
        name: 'ref-store',
        storage: createJSONStorage(() => localStorage),
    },
));

export const StoreTerrUnit = create(persist<TerrUnitStore>(
    (set) => ({
        isLoading: false,
        isFinished: true,
        response: [],
        search:  async (value:string) => {
            try {
                set({ isLoading: true, isFinished: false});
                const data:Array<{ key: string; name: string;ordre:number;terrUnitKey: string;ref:string,level:number}> = await RefService.getTerrUnits(value);                
                set(() =>({ isLoading: false, response:data ,isFinished: true}));
            }   catch (err: any) {
                const error = 
                err?.message || err?.data?.message || 'Unexpected network error.';
              set(() => ({ isLoading: false, error,isFinished: false }));
            }
        },
    }),
    {
        name: 'territorial-unit-store',
        storage: createJSONStorage(() => localStorage),
    },
));

export const StoreFile = create(persist<FileStore>(
    (set) => ({
        isFinished: false,
        isLoading: false,
        response: [],
        upload: async (formdata:FormData, importType:string) => {
            try {
                set({isLoading: true});
                const data:Array<FileData> = await LinnaeService.postFile(formdata, importType);
                set(() =>({ isLoading: false, response:data, isFinished: true }));
            }   catch (err: any) {
                const error =
                err?.message || err?.data?.message || 'Unexpected network error.';
                set(() => ({ error,isFinished: false }));
            }
        },
    }),
    {
        name: 'file-store',
        storage: createJSONStorage(() => localStorage),
    },
));

export const StoreTaxaName = create(persist<TaxaSearchStore>(
    (set) => ({
        isLoading: false,
        isFinished: false,
        response: [],
        search:  async (value:string) => {
            try {
                set({ isLoading: true, isFinished: false});
                const data:Array<taxons> = await RefService.getTaxaName(value);                
                set(() =>({ isLoading: false, response:data ,isFinished: true}));
            }   catch (err: any) {
                const error = 
                err?.message || err?.data?.message || 'Unexpected network error.';
              set(() => ({ isLoading: false, error,isFinished: false }));
            }
        },
    }),
    {
        name: 'taxa-store',
        storage: createJSONStorage(() => localStorage),
    },
));

export const StoreValidTaxa = create(persist<TaxaValidStore>(
    (set) => ({
        isLoading: false,
        isFinished: false,
        validNameResponse: <ValidTaxa>{},
        find:  async (key:string) => {
            try {
                set({ isLoading: true, isFinished: false});
                const data:Array<ValidTaxa> = await RefService.getTaxaDetails(key);                
                set(() =>({ isLoading: false, validNameResponse:data.taxon ,isFinished: true}));
            }   catch (err: any) {
                const error = 
                err?.message || err?.data?.message || 'Unexpected network error.';
              set(() => ({ isLoading: false, error,isFinished: false }));
            }
        },
    }),
    {
        name: 'taxa-synonym-store',
        storage: createJSONStorage(() => localStorage),
    },
));

// TODO : change taxa version to dynamic
// export const StoreTaxaVersion = create(persist<TaxaVersionStore>(
//     (set) => ({
//         isLoading: false,
//         isFinished: false,
//         response: "",
//         find:  async () => {
//             try {
//                 set({ isLoading: true, isFinished: false});
//                 const data:Array<TaxaVersion> = await RefService.getTaxaVersion();                
//                 set(() =>({response:data, isFinished: true}));
//             }   catch (err: any) {
//                 const error = 
//                 err?.message || err?.data?.message || 'Unexpected network error.';
//               set(() => ({ isLoading: false, error,isFinished: false }));
//             }
//         },
//     }),
//     {
//         name: 'taxa-version-store',
//         storage: createJSONStorage(() => localStorage),
//     },
// ));

export const StoreStatus = create(persist<TaxonStatusStore>(
    (set) => ({
        isLoading: false,
        isFinished: false,
        response: [],
        upload:  async (formdata:FormData) => {
            try {
                set({ isLoading: true});
                const data: Array<StatusTaxonResponse> = await LinnaeService.postConsolidation(formdata);
                set(() =>({ isLoading: false, response: data, isFinished: true }));
            }   catch (err: any) {
                const error = 
                err?.message || err?.data?.message || 'Unexpected network error.';
              set(() => ({ isLoading: false, error,isFinished: false }));
            }
        },
    }),
    {
        name: 'taxa-status-store',
        storage: createJSONStorage(() => localStorage),
    },
));