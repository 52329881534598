import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { 
  DataGrid, 
  GridRowsProp, 
  GridColDef,
  useGridApiRef
} from '@mui/x-data-grid';
import { StoreStatus } from '../../hooks/store';

export default function StatusTable() {

  const { response: statusList } = StoreStatus();

  // Création des colonnes uniques pour le tableau
  function generateUniqueColumns(statusList:any) {
    const columns: any = {};
    statusList.map((status:any) => {
      const columnKey = `${status.terrUnit.terrUnitName} : ${status.statusType.shortened}`;
      columns[columnKey] = {
        terrUnitName: status.terrUnit.terrUnitName,
        statusType: status.statusType.shortened
      };
    });

    const columnArray = Object.values(columns);

    return {
      columns: columnArray
    };
  }

  const stringifyTerrUnitColumn = (generateUniqueColumns(statusList).columns as { terrUnitName: any, statusType: any }[]).map((item) => {
    return item.terrUnitName + ' : ' + item.statusType;
  }).sort();

  // Création des lignes uniques pour le tableau
  const generateUniqueRows = (statusList: any[], columnsTerrUnit: string[]): GridRowsProp => {
    const taxonMap = new Map();

    statusList.map((item) => {
      const txKey = item.taxa.txScientificName;
      
      if (!taxonMap.has(txKey)) {
        taxonMap.set(txKey, {
          id: item.taxa.txKey,
          taxon: item.taxa.txScientificName,
          ...Object.fromEntries(columnsTerrUnit.map(col => [col, ""]))
        });
      }
      columnsTerrUnit.forEach(columnKey => {
        const terrUnitKey = statusList.find((status: { terrUnit: { terrUnitKey: any } }) => status.terrUnit.terrUnitKey === item.terrUnit.terrUnitKey)?.terrUnit.terrUnitKey;
        if (item.terrUnit.terrUnitKey === terrUnitKey && item.statusType.shortened === columnKey.split(' : ')[1]) {
          taxonMap.get(txKey)[columnKey] = item.statusValue || item.statusType.value;
        }
      });
    });
    return Array.from(taxonMap.values());
  };

  var rows: GridRowsProp = generateUniqueRows(statusList, stringifyTerrUnitColumn);

  var columns: GridColDef[] = [
    {
      field: 'taxon',
      headerName: 'Taxon',
      renderHeader: () => (
        <div style={{ 
          fontWeight: 'bold'
        }}>
          {'Taxons'}
        </div>
      ),
      width: 300,
      align: 'left',
      headerAlign: 'left'
    },
    ...stringifyTerrUnitColumn.map((item: any) => (
      {
        field: item, 
        headerName: item, 
        renderHeader: () => (
          <div style={{ 
            fontWeight: 'bold'
          }}>
            {item}
          </div>
        ),
        width: 200
      }))
  ];

  const apiRef = useGridApiRef();

  return (
    <Box
      sx={{
        height: 380,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        disableRowSelectionOnClick
        rows={rows}
        columns={columns}
        apiRef={apiRef}
      />
      <Box
      sx={{
        display:"flex",
        justifyContent:" center",
        m: 2
      }}
    >
      <Button
        startIcon={<FileDownloadIcon />}
        color='primary'
        onClick={() => apiRef.current.exportDataAsCsv(
          {
            allColumns: true,
            fileName: 'Linnae_status_table',
            delimiter: ';',
            utf8WithBom: true,
          }
        )}
        variant='outlined'
      >
        Exporter
      </Button>
    </Box>
  </Box>
  );
}