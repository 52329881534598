import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

/**
 * TableCSV is a React functional component that renders a table with pagination.
 * The table displays data provided in the `rows` array, which can be filled using the `fillArray` function.
 * The table uses Material-UI components for styling and layout.
 *
 * @component
 * @example
 * // Example usage:
 * // Import the component and the fillArray function
 * import { TableCSV, fillArray } from './Table';
 * 
 * // Fill the table with data
 * fillArray([{ scientificName: 'Homo sapiens' }, { scientificName: 'Pan troglodytes' }]);
 * 
 * // Render the component
 * <TableCSV />
 *
 * @returns {JSX.Element} The rendered table component.
 */

interface Column {
  id: 'scientificName';
  label: string;
  minWidth?: number;
  align?: 'right';
}

interface Row {
  id?: number;
  scientificName: string;
}

const columns: readonly Column[] = [
    { id: 'scientificName', label: 'scientificName', minWidth: 170},
];

var rows: Row[] = [{"scientificName":""}];

export  function fillArray(csvData:Array<{"scientificName":""}>) {
  rows = csvData;
}

export function TableCSV() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (_event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    return (
      <Paper sx={{ width: '100%', overflow: 'hidden', mt: 1, mb: 1 }}>
        <TableContainer sx={{ maxHeight: 240 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: 'bold',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell 
                            key={column.id}
                            align={column.align}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    );
}
