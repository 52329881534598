import { useEffect } from 'react';
// Data Import
import { FileArray } from '../../utils/types';
import { TaxaNameSelection } from './SelectTaxaName';
import { StoreStatus } from '../../hooks/store';
// MUI Table Import
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  GridRowsProp,
  DataGrid,
  GridColDef,
  useGridApiRef,
  GridFilterModel
} from '@mui/x-data-grid';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { ButtonGroup, FormControlLabel, Icon, Switch } from '@mui/material';

export default function MatchingTable({fileData, handleNext, tab}:any) {
  const { upload } = StoreStatus();
  const { isLoading: isLoadingStatus } = StoreStatus();
  const apiRef = useGridApiRef();

  var initialRows: GridRowsProp = [{}];
  let values:FileArray[] = fileData.consolidateReport? fileData.consolidateReport.values:null;
  initialRows = values?.map((item: any, index: number) => ({
    ...item,
    id: index + 1,
    isTaxonFound: item.rightValueName === item.leftValueName,
    isTaxonAlmostFound: item.rightValueName !== item.leftValueName && item.rightValueName !== null,
  }));
  const [rows, setRows] = React.useState(initialRows);
  const [validRow, setValidRow] = React.useState(false);

  function handleStatusUpload() {
    const importType = tab === 0 ? "biblio" : "status-biblio";
    try {
      upload(fileData, importType).then(() => {
        handleNext();
      });
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    const rightValueName = rows.map((row) => row.rightValueName);
    if (rightValueName.includes(null)) {
      setValidRow(false);
    }else {
      setValidRow(true);
    }
  }, [fileData, rows]);

  const columns: GridColDef[] = [
    {
      field: 'leftValueName',
      headerName: 'Nom cité',
      renderHeader: () => (
        <div style={{ 
          fontWeight: 'bold'
        }}>
          {'Nom cité'}
        </div>
      ),
      flex: 400,
      align: 'left',
      headerAlign: 'left',
      disableColumnMenu: true,
    },
    {
      field: 'rightValueName',
      headerName: 'Nom consolidé',
      renderHeader: () => (
        <div style={{
          fontWeight: 'bold'
        }}>
          {'Nom consolidé'}
        </div>
      ),
      renderEditCell: (params) => {
        var id = params.id;
        return (
          <TaxaNameSelection rowId={id} rowsArray={rows} setRows={setRows} />
        );
      },
      renderCell: (params) => {
        return (
            <div>
            {params.value === null ? (
              <Icon color="error" sx={{ mr: 0.5 }}>
                <CancelIcon fontSize='small' sx={{ mb: 1 }} />
              </Icon>
            ) : params.value === params.row.leftValueName ? (
              <Icon color="primary" sx={{ mr: 0.5 }}>
                <CheckCircleIcon fontSize='small' sx={{ mb: 1 }} />
              </Icon>
            ) : (
              <Icon color="warning" sx={{ mr: 0.5 }}>
                <WarningIcon fontSize='small' sx={{ mb: 1 }} />
              </Icon>
            )}
            {params.value}
            </div>
        );
      },
      flex: 400,
      align: 'left',
      headerAlign: 'left',
      disableColumnMenu: true,
      editable: true,
    },
    // Colomne de similarité non utilisée car imprecise
      /*{
        field: 'similarity',
        renderHeader: () => (
          <div style={{ 
            fontWeight: 'bold'
          }}>
            Similarité
          </div>
        ),
        width: 120,
        align: 'center',
        headerAlign: 'center'
      }*/
    {
      field: 'validName',
      headerName: 'Nom valide',
      renderHeader: () => (
        <div style={{ 
          fontWeight: 'bold'
        }}>
         {'Nom valide'}
        </div>
      ),
      flex: 400,
      align: 'left',
      headerAlign: 'left',
      disableColumnMenu: true,
    },
    {
      field: 'isTaxonFound',
      headerName: 'Taxon Found',
      renderHeader: () => (
        'Taxon Found'
      ),
      disableExport: true
    },
    {
      field: 'isTaxonAlmostFound',
      headerName: 'Taxon Almost Found',
      renderHeader: () => (
        'Taxon Almost Found'
      ),
      disableExport: true
    }
  ];

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });

  const columnVisibilityModel = {
    isTaxonFound: false,
    isTaxonAlmostFound: false,
  };

  const [activeSwitch, setActiveSwitch] = React.useState<string | null>(null);

  const handleSwitchChange = (switchName: string, filter: any) => {
    setActiveSwitch((prevSwitch) => (prevSwitch === switchName ? null : switchName));
    setFilterModel({
      items: activeSwitch === switchName ? [] : [filter],
    });
  };

  return (
    <Box
      sx={{
        height: 410,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <Box sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        mb: 2,
      }}>
        <FormControlLabel
          control={
            <Switch
            checked={activeSwitch === 'isTaxonFound'}
            onChange={() => handleSwitchChange('isTaxonFound', {
              field: 'isTaxonFound',
              operator: 'equals',
              value: 'true'
            })}
          />
          }
          label={
          <Box sx={{ display: 'flex', alignItems: 'center'}}>
            <CheckCircleIcon color="primary" sx={{mr: 0.5}} />
            Afficher les taxons trouvés
          </Box>
          }
        />
        <FormControlLabel
          control={
            <Switch
              checked={activeSwitch === 'isTaxonAlmostFound'}
              onChange={() => handleSwitchChange('isTaxonAlmostFound', {
                field: 'isTaxonAlmostFound',
                operator: 'equals',
                value: 'true'
              })}
            />
          }
          label={
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
              <WarningIcon color="warning" sx={{mr: 0.5}} />
              Afficher les taxons incertain
            </Box>
          }
        />
        <FormControlLabel
        control={
          <Switch
            checked={activeSwitch === 'rightValueName'}
            onChange={() => handleSwitchChange('rightValueName', {
              field: 'rightValueName',
              operator: 'isEmpty',
            })}
          />
          }
        label={
          <Box sx={{ display: 'flex', alignItems: 'center'}}>
            <CancelIcon color="error" sx={{mr: 0.5}} />
            Afficher les taxons non trouvés
          </Box>
        }
        />
      </Box>
      <DataGrid
        disableRowSelectionOnClick
        rows={rows}
        columns={columns}
        processRowUpdate={(_oldRow, newRow) => {
          fileData.consolidateReport.values[newRow.id - 1] = newRow;
          return newRow;
        }}
        columnVisibilityModel={columnVisibilityModel}
        filterModel={filterModel}
        apiRef={apiRef}
      />
      <Box
      sx={{
        display:"flex",
        justifyContent:" center",
        m: 2
      }}
      >
        <ButtonGroup variant="outlined">
        <Button
          startIcon={<FileDownloadIcon />}
          color='primary'
          onClick={() => apiRef.current.exportDataAsCsv(
            {
              allColumns: true,
              fileName: 'Linnae_consolidation',
              delimiter: ';',
              utf8WithBom: true,
            }
          )}
          variant='outlined'
        >
          Exporter
        </Button>
      {tab == 1 && (
        <div>
          {isLoadingStatus === false && (
            <Button
              color='primary'
              onClick={handleStatusUpload}
              variant='contained'
              disabled={validRow === false}
            >
              Générer la liste de statuts
            </Button>
          )}
          {isLoadingStatus === true && (
            <LoadingButton
              loading={true}
              startIcon={<SaveIcon />}
              loadingPosition="start"
              variant="contained"
              color="secondary"
              sx={{ mr: 1 }}
            >
              Recherche des status...
            </LoadingButton>
          )}
        </div>
      )}
      </ButtonGroup>
      </Box>
    </Box>
  );
}